import { Line } from 'react-chartjs-2';


export default function AccountsDashboardLine({data}){

    let labels = data.map(x => x.label)
    let values = data.map(x => x.value)
    
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Chart.js Line Chart',
          },
        },
      };

    
    const lineData = {
        labels,
        datasets: [
          {
            fill: true,
            label: 'Dataset 2',
            data: values,
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      };

      return (
        <div>
            <Line options={options} data={lineData} />
        </div>
      )
}