import AccountsDashboardPie from './AccountsDashboardPie';
import AccountsDashboardLine from './AccountsDashboardLine';
import {getMonthlyDates, GetValuesByDates, dateToMonthYear} from './Util'


import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';


import {GetRecordByDate} from "./Util"

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

export default function AccountsDashboard({accounts, accountsByType, selectedDate, selectedAccountId}){

    //Title - x by Type, x by account
    //Data [a, b, c, d, e, f]
    //Labels[1, 2, 3, 4, 5, 6]
    //Colours are auto generated 

    const totalsBySectionData = []
    const totalsByAccountData = []
    const currentAccountOverTime = []
    const totalOverTime = []

    if(selectedDate !== undefined){
        for (var key in accountsByType) {
            if (accountsByType.hasOwnProperty(key)) {
                let accounts = accountsByType[key];

    
                const total = accounts.reduce((prevValue, currentAccount) => {
                    let record = GetRecordByDate(currentAccount, selectedDate)
                    if(record.value !== ''){prevValue += record.value}
                    return prevValue
                }, 0)

                totalsBySectionData.push({label: key, value: total})
            }
        }


        for(var index in accounts){
            let account = accounts[index]
            let record = GetRecordByDate(account, selectedDate)

            totalsByAccountData.push({label: account.accountName, value:record.value})
        }
    }

    if(selectedAccountId !== undefined){
        let account = accounts.find(({id}) => id === selectedAccountId);

        if(account.history.length > 0){
            const oldestRecord = account.history.reduce(function(prev, curr) {
                return prev.date < curr.date ? prev : curr;
            });
            const newestRecord = account.history.reduce(function(prev, curr) {
                return prev.date > curr.date ? prev : curr;
            });
    
            const dates = getMonthlyDates(oldestRecord.date, newestRecord.date)
    
            const values = GetValuesByDates(account, dates)
    
            dates.map((date, index) => currentAccountOverTime.push({label: dateToMonthYear(date), value: values[index]}))
        }
    }


    let oldestDate = null
    let newestDate = null

    accounts.forEach(account => {

        if(account.history.length > 0){
            const oldestRecord = account.history.reduce(function(prev, curr) {
                return prev.date < curr.date ? prev : curr;
            });
            const newestRecord = account.history.reduce(function(prev, curr) {
                return prev.date > curr.date ? prev : curr;
            });
    
            if(oldestDate === null || oldestDate > oldestRecord.date){
                oldestDate = oldestRecord.date
            }
    
            if(newestDate === null || newestDate < newestRecord.date){
                newestDate = newestRecord.date
            }
        } 
    })

    if(oldestDate !== null && newestDate !== null){
        let totalValues = []
        
        const totalDates = getMonthlyDates(oldestDate, newestDate)
        accounts.forEach(account => {
            const values = GetValuesByDates(account, totalDates)
            values.forEach((value, index) => {
                totalValues[index] ??= 0
                if(value !== ''){totalValues[index] += value}
            })
        })

        totalDates.forEach((date, index) => {
            totalOverTime.push({label: dateToMonthYear(date), value:totalValues[index]})
        })
    }

    


    return(
        <div> 
            {totalOverTime.length > 0 && <AccountsDashboardLine data={totalOverTime}/>}
            {currentAccountOverTime.length > 0 && <AccountsDashboardLine data={currentAccountOverTime}/>}
            {totalsBySectionData.length > 0 && <AccountsDashboardPie data={totalsBySectionData}/>}
            {totalsByAccountData.length > 0 && <AccountsDashboardPie data={totalsByAccountData}/>}
        </div>  
    )  
}