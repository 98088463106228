import AccountsTableRow from "./AccountsTableRow"

import {GetRecordByDate, GetValuesByDates} from "./Util"

export default function AccountsTableSection({
        accounts, 
        sectionName, 
        updateAccountValue, 
        updateAccountName, 
        addNewAccount, 
        updateSelectedDate,
        updateSelectedAccount,
        selectedDate,
        selectedAccountId,
        dates}){
    
    let selectedColumn = dates.findIndex(function(x) { 
        return selectedDate === undefined ? false : x.getMonth() === selectedDate.getMonth() && x.getYear() === selectedDate.getYear()
    });

    let totals = []
    
    let tableRows = accounts.map(account => {
        let values = GetValuesByDates(account, dates)

        dates.forEach((currentDate, index) => {
            let value = GetRecordByDate(account, currentDate).value
            totals[index] ??= 0
            if(value !== ''){totals[index] += value}
        })

        return(
        <AccountsTableRow key={account.id} 
            accountId={account.id} 
            accountName={account.accountName} 
            handleNameChange={handleNameChange} 
            handleValueChange={handleValueChange} 
            values={values} 
            cellClicked={cellClicked}
            rowClicked={(e) => updateSelectedAccount(e, account.id)}
            selectedColumn={selectedColumn}
            isSelected={selectedAccountId === account.id}
        />)})
    
    function handleValueChange(event, accountId, index){
        event.preventDefault()
        let newValue = + parseFloat(event.target.value).toFixed(2)
        newValue = isNaN(newValue) ? '' : newValue
        updateAccountValue(accountId, dates[index], newValue)
    }

    function handleNameChange(event, accountId){
        event.preventDefault()
        updateAccountName(accountId, event.target.value)
    }

    function cellClicked(event, index){
        event.preventDefault()
        //event.stopPropagation() - moved to row clicked
        updateSelectedDate(dates[index])
    }

    return (
        <tbody id={sectionName} key={sectionName}>
            <tr>
                <th colSpan="2" className="text-left pt-6">{sectionName}</th>
            </tr>
            {tableRows}
            <AccountsTableRow 
                key={sectionName + "addRow"} 
                isEmpty={true} 
                addNewAccount={() => addNewAccount(sectionName)}
                selectedColumn={selectedColumn}    
            />
            <AccountsTableRow 
                key={sectionName + "total"} 
                values={totals} 
                isTotal={true}
                cellClicked={cellClicked}
                selectedColumn={selectedColumn}
            />
        </tbody>
    )
}