import { Pie } from 'react-chartjs-2';

export default function AccountsDashboardPie({data}){

    let labels = data.map(x => x.label)
    let values = data.map(x => x.value)


    const pieData = {
        labels: labels,
        datasets: [{
          data: values,
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
            'rgb(144, 205, 86)',
            'rgb(255, 26, 86)',
            'rgb(3, 205, 3)',
            'rgb(120, 200, 3)',
          ],
          hoverOffset: 4
        }]
      };
    
    return(
        <div>
            <Pie data={pieData}/>
        </div>
    )
}