
export default function AccountsTableRow({
        accountName, 
        accountId,  
        values, 
        handleValueChange, 
        handleNameChange, 
        isTotal, 
        isEmpty, 
        addNewAccount, 
        cellClicked,
        rowClicked,
        isSelected,
        selectedColumn
    }){    
    
    let dataRows = []
    if(values){
        dataRows = values.map((curr, i, array) => {
            let prevValue = array[i-1] ?? curr

            let comparisonClass = curr > prevValue ? "value-higher" : curr < prevValue ? "value-lower" : "value-equal"
            
            return(
                <td key={accountId + i.toString()} className={`border-l ${comparisonClass} ${selectedColumn === i && "column-selected"}`} onClick={e => cellClicked(e, i)}>
                    <input className='w-full' type="number" value={curr} onChange={e => handleValueChange(e, accountId, i)}/>
                </td>
            )
        })
    }

    let trKey = isEmpty ? "empty" : isTotal ? "total" : accountId + "row"
    return (
        <tr key={trKey} className={`border ${isSelected && "row-selected"} ${isTotal ? "row-total" : isEmpty ? "row-empty" : "row-account"}`} onClick={(isEmpty ? addNewAccount : rowClicked) }>
            {values && 
                <td>
                    <input className='w-full' type="text" value={accountName} onChange={e => handleNameChange(e, accountId)}/>
                </td>
            }
            {values && dataRows}
            {isEmpty && <td colSpan={2}>Click to Add</td>}
        </tr>
    )
}

//TODO - Split into TotalsRow, AddRow and AccountRow Componenets