import AccountsTableSection from "./AccountsTableSection"
import MonthYearPicker from './MonthYearPicker';

import {getMonthlyDates, dateToMonthYear} from './Util'

export default function AccountsTable({
    accountsByType, 
    fromDate, 
    toDate, 
    updateAccountValue, 
    updateAccountName, 
    handleRangeChange,
    addNewAccount, 
    updateSelectedDate,
    updateSelectedAccount,
    selectedDate,
    selectedAccountId}){

    const dates = getMonthlyDates(fromDate, toDate);

    const monthlyHeaders = dates.map(date => {
        let dateString = dateToMonthYear(date)
        return (<th key={dateString}>{dateString}</th>)
    })

    const sections = []
    for (var key in accountsByType) {
        if (accountsByType.hasOwnProperty(key)) {
            let accounts = accountsByType[key];

            sections.push( <AccountsTableSection 
                key={key} 
                sectionName={key} 
                accounts={accounts} 
                updateAccountName={updateAccountName} 
                updateAccountValue={updateAccountValue} 
                addNewAccount={addNewAccount} 
                updateSelectedDate={updateSelectedDate}
                updateSelectedAccount={updateSelectedAccount}
                selectedDate={selectedDate}
                selectedAccountId={selectedAccountId}
                dates={dates}
            />)
        }
    }

    
    
        

    return(
        <div>
            <MonthYearPicker selectedDate={fromDate} updateDate={handleRangeChange} type="from"/>
            <MonthYearPicker selectedDate={toDate} updateDate={handleRangeChange} type="to"/>
            <div className="">
                <table className="border-collapse table-auto w-full">
                    <thead>
                        <tr>
                            <th></th>
                            {monthlyHeaders}
                        </tr>
                    </thead>
                    {sections}
                </table>
            </div>
        </div>
    )
}
  
//TODO - Add/Remove/Change/Scroll through dates