export default function ({updateDate, selectedDate, type}){
    console.log(selectedDate, type)
    
    return (
        <div>
            <input type="date" value={formatDate(selectedDate)} onChange={e => updateDate(e, type)}/>
        </div>
    )
}

//https://stackoverflow.com/a/38148759/9100849

function formatDate(date) {
    var d = new Date(date);
    return d.toISOString().slice(0, 10)
}