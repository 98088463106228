import './App.css';
import AccountsTable from './components/AccountsTable';
import AccountsDashboard from './components/AccountsDashboard'
import {useState} from 'react'
import {nanoid} from 'nanoid'



function App() {  
  const [selectedDate, setSelectedDate] = useState()
  const [selectedAccountId, setSelectedAccountId] = useState()

  const [fromDate, setFromDate] = useState(new Date(2022, 8))
  const [toDate, setToDate] = useState(new Date(2022, 12))

  const [accounts, setAccounts] = useState([
    {
      accountName: "Freetrade",
      id: nanoid(),
      history: [
        { date: new Date(2022, 8), value: 100},
        { date: new Date(2022, 9), value: 200},
        { date: new Date(2022, 10), value: 300}
      ],
      type: "Stocks"
    },
    {
      accountName: "Vanguard",
      id: nanoid(),
      history: [
        { date: new Date(2022, 8), value: 20},
        { date: new Date(2022, 9), value: 25},
        { date: new Date(2022, 10), value: 30}
      ],
      type: "Stocks"
    },
    {
      accountName: "Vanguard",
      id: nanoid(),
      history: [
        { date: new Date(2022, 8), value: 21},
        { date: new Date(2022, 9), value: 21},
        { date: new Date(2022, 10), value: 31}
      ],
      type: "Stocks"
    },
    {
      accountName: "HSBC",
      id: nanoid(),
      history: [
        { date: new Date(2022, 8), value: 100},
        { date: new Date(2022, 9), value: 150},
        { date: new Date(2022, 10), value: 90}
      ],
      type: "Savings"
    },
    {
      accountName: "Chase",
      id: nanoid(),
      history: [
        { date: new Date(2022, 8), value: 5},
        { date: new Date(2022, 9), value: 10},
        { date: new Date(2022, 10), value: 15}
      ],
      type: "Savings"
    }
  ])


  //Group By - https://dmitripavlutin.com/javascript-array-group/
  const accountsByType = accounts.reduce((group, account) => {
    const { type } = account;
    group[type] = group[type] ?? [];
    group[type].push(account);
    return group;
  }, {});

  function updateAccountValue(accountId, newDate, newValue){
    setAccounts(oldAccounts => oldAccounts.map(account => {
      if(account.id === accountId){
        let record = account.history.find(({date}) => date.getMonth() === newDate.getMonth() && date.getYear() === newDate.getYear())
        if(record !== undefined){
          record.value = newValue
        }
        else{
          account.history.push({date: newDate, value: newValue})
        }
      }
      return account
    }))
  }

  function updateAccountName(accountId, newName){
    setAccounts(oldAccounts => oldAccounts.map(oldAccount => {
      let newAccount = oldAccount
      if(newAccount.id === accountId){
        newAccount.accountName = newName
      }
      return newAccount
    }))
  }

  function addNewAccount(sectionName){
    setAccounts(old => 
      [...old, { 
        accountName: "", 
        id: nanoid(),
        history: [],
        type: sectionName}])
  }

  function updateSelectedDate(date){
    setSelectedDate(date)
  }

  function updateSelectedAccount(event, id){
    event.preventDefault()
    event.stopPropagation()
    setSelectedAccountId(id)
  }

  function handleRangeChange(event, key){
    key == "from" ? 
      setFromDate(new Date(event.target.value)) : 
      setToDate(new Date(event.target.value))
  }

  function clearSelected(event){
    event.preventDefault();
    setSelectedDate()
    setSelectedAccountId()
  }

  return (
    <div className="page">
      <div onClick={(e) => clearSelected(e)}> Clear </div>
      <AccountsTable 
        accountsByType={accountsByType} 
        fromDate={fromDate} 
        toDate={toDate} 
        updateAccountValue={updateAccountValue} 
        updateAccountName={updateAccountName} 
        addNewAccount={addNewAccount}
        handleRangeChange={handleRangeChange}
        updateSelectedDate={updateSelectedDate}
        updateSelectedAccount={updateSelectedAccount}
        selectedDate={selectedDate}
        selectedAccountId={selectedAccountId}
        />
      <AccountsDashboard 
        accountsByType={accountsByType}
        selectedDate={selectedDate}
        selectedAccountId={selectedAccountId}
        accounts={accounts} />
    </div>
  );
}

export default App;





// ----------- Features -----------
//TODO - Full Totals at the top of the page

//TODO - Add & Remove Dates

//TODO - Remove Account
//TODO - Edit Account Section??

//TODO - Save to browser storage
//TODO - Export & Import (Json??)
//TODO - Interupt CTRL + S --> Saves versions??

// ----------- Cleanup -----------
//TODO - Same values are being calculated in multiple places - 
  //!! need to centralises these calculations