//Purpose

//Shouldn't autopopulate value
//Also shouldn't be a record if all values are '' however 0 is a valid record

//Instead "Add month" button will copy current values to next month
export function GetRecordByDate(account, currentDate){
    //TODO - Need to fix for larger date ranges
    let record = 
    //Value for month exists
    account.history.find(({date}) => date.getMonth() === currentDate.getMonth() && date.getYear() === currentDate.getYear())
    
    //Value is for a date later than in history
    ??  //!!TODO!!((account.history.some(({date}) => date.getMonth() > currentDate.getMonth() && date.getYear() >= currentDate.getYear()))
        //Value for a previous month exists
        //!!TODO!!?  account.history.filter(({date}) => date < currentDate).sort((a, b) => (a.date < b.date) ? 1 : -1)[0]:
        
        {date: new Date(currentDate), value: ''}//!!TODO!!)
    //Else
    //?? {date: new Date(currentDate), value: 0}

    return record;
}

export function GetValuesByDates(account, dates){
    let values = dates.map((currentDate, index) => {

        let record = GetRecordByDate(account, currentDate)

        let value = record.value === '' ? '' : record.value || 0         

        return value
    })

    return values
}

export function getMonthlyDates(startDate, stopDate) {
    var dateArray = []
    var currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(new Date (currentDate));
        currentDate = new Date(currentDate).setMonth(new Date(currentDate).getMonth() + 1)
    }
    return dateArray;
}

export function dateToMonthYear(date){
    return date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
}